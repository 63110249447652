import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import api from './api'
import TokenMixin from '@/mixins/TokenMixin'
import PageHeader from './components/layout/PageHeader.vue'
import Fetcher from './prototype/Fetcher'
import CommonFn from './prototype/CommonFn'
import Formatter from './prototype/Formatter'
import Validator from './prototype/Validator'

Vue.config.productionTip = false

Vue.use(api)
Vue.use(CommonFn)
Vue.use(Validator)
Vue.use(Formatter)
Vue.use(Fetcher)
Vue.mixin(TokenMixin)

Vue.component('PageHeader', PageHeader)

Vue.prototype.$mediaPath = 'https://media.marsantmotors.com/';


function formatText(list, value) {
  if (!value) return '';
  const target = list.find(e => e.value === value);
  return target ? target.text : value;
}

const statusOptions = [
  { text: '啟用中', value: 'active' },
  { text: '停用', value: 'inactive' },
];
Vue.prototype.$statusOptions = statusOptions;

const appointmentStatusOptions = [
  { text: '已預約', value: 'active' },
  { text: '已完成', value: 'completed' },
  { text: '已取消', value: 'cancel' },
  { text: '已過期', value: 'expired' },
];
Vue.prototype.$appointmentStatusOptions = appointmentStatusOptions;
Vue.filter('formatAppointmentStatus', function(value) { return formatText(appointmentStatusOptions, value); });

const couponStatusOptions = [
  { text: '未使用', value: 'unused' },
  { text: '已使用', value: 'used' },
  { text: '已過期', value: 'expired' },
  { text: '已取消', value: 'cancelled' },
];
const couponStatusOptionsWithTransferred = [
  ...couponStatusOptions,
  { text: '已轉贈', value: 'transferred' },
];
Vue.prototype.$couponStatusOptions = couponStatusOptions;
Vue.filter('formatCouponStatus', function(value) { return formatText(couponStatusOptionsWithTransferred, value); });

const orderStatusOptions = [
  { text: '已完成', value: 'active' },
  { text: '已取消', value: 'inactive' },
];
Vue.prototype.$orderStatusOptions = orderStatusOptions;
Vue.filter('formatOrderStatus', function(value) { return formatText(orderStatusOptions, value); });

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
