


export default {
    install(Vue, option) {
        Vue.prototype.$validate = new (function () {
            const DataValid = function (data) {
                if (data !== undefined && data !== null && data !== '') {
                    if (typeof data === 'object') {
                        return Object.keys(data).length > 0
                    } else if (Array.isArray(data)) {
                        return data.length > 0
                    }

                    if (data.length > 0) {
                        return data.trim().length > 0
                    }
                    return true;

                } else {
                    return false
                }
            }

            const regex = {
                email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                // phone: /^[2-9][0-9]{7}$/,
                phone: /^(\+?)[0-9]{2,18}$/i,
                hkMobile: /^[3-9][0-9]{7}$/,
                number: /^[0-9]+$/i,
                // floating: /^[0-9]+([.]?[0-9]{1,2})?$/i,
                floating: /^[0-9]+([.]?[0-9]{1})?$/i,
                floatingWithoutLimit: /^[0-9]+([.]?[0-9]+)?$/i,
                negativeNumber: /^[-]?[0-9]+$/i,
                negativeFloating: /^[-]?[0-9]+([.]?[0-9]+)?$/i,
                blogSlug: /^[\u4e00-\u9fff\u3400-\u4dff\uf900-\ufaffA-za-z0-9-_]+$/i,
                hkid4Letter: /^[A-Z]{1,2}[0-9]{4}$/,
                date: /^\d{4}-\d{2}-\d{2}$/, // YYYY-MM-DD
                regexIdNum: /^[a-zA-Z0-9]{8,9}$/i,
                hkPhone: /^(^[2|3|4|5|6|7|8|9])+([0-9]{7})$/,
                twPhone: /^(^[1-9])+([0-9]{8})$/,
                macauPhone: /^([0-9]{8})$/,
                chinaPhone: /^(^[1])+([0-9]{10})$/,
                chinName: /^[\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u017F\u3400-\u4DBF\u4E00-\u9FFF]{2,40}$/,
                engName: /^[a-zA-Z\s.]{2,40}$/,
                password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                username: /^[a-zA-Z0-9_-]+$/
            }

            this.DataValid = data => {
                return DataValid(data);
            }

            this.required = value => {
                if (DataValid(value) && typeof value === 'string') {
                    return value.trim().length > 0
                }

                return DataValid(value);
            }

            this.regexNumber = (value) => {
                return regex.number.test(value);
            }

            this.regexFloating = (value) => {
                return regex.floatingWithoutLimit.test(value);
            }

            this.regexDateStr = (value) => {
                const valid = regex.date.test(value);
                if (!valid) {
                    return false;
                }

                const month = parseInt(value.substring(5, 7));
                if (month < 1 || month > 12) {
                    return false;
                }

                const daysInMonths = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
                const date = parseInt(value.substring(8, 10));

                if (date < 1 || date > daysInMonths[month - 1]) {
                    return false;
                }

                return true;
            }

            this.isNumber = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.number.test(value) || '請輸入有效數字'
            }

            this.isFloating = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.floating.test(value) || '請輸入有效數字'
            }

            this.isPhoneNumber = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.hkPhone.test(value) || '請輸入有效電話號碼'
            }

            this.isFaxNumber = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.number.test(value) || '傳真號碼格式不正確'
            }

            this.isEmail = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.email.test(value) || '請輸入有效電郵'
            }

            this.isEngName = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.engName.test(value) || '英文名稱格式不正確'
            }

            this.isChinName = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.chinName.test(value) || '中文名稱格式不正確'
            }

            this.isValidPassword = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return true;
                // return regex.password.test(value) || '請輸入最少8個英文或數字字元作為密碼'
            }

            this.isUsername = (value, allowEmpty = false) => {
                if (!DataValid(value)) {
                    if (allowEmpty) {
                        return true;
                    } else {
                        return '此位置不能留空';
                    }
                }

                return regex.username.test(value) || '只可輸入數字、英文、"-" 及 "_" 等字元';
            }

            const checkDoubleFileExtension = (fileName) => {
                const extension = fileName.match(/\.(.+)$/)[1];

                if (extension.split(".").length !== 1) {
                    return true;
                }

                return false;
            }

            const imgSizeCheck = (file) => {
                return new Promise((resolve) => {
                    var dimenMinW = 0,
                        dimenMinH = 0,
                        dimenMaxW = 5472,
                        dimenMaxH = 5472;
                    var reader = new FileReader();
                    reader.onload = function () {
                        var image = new Image();
                        image.src = reader.result;
                        image.onload = function () {
                            var height = this.height;
                            var width = this.width;

                            if (
                                (dimenMinW > 0 && width < dimenMinW) ||
                                (dimenMinH > 0 && height < dimenMinH) ||
                                (dimenMaxW > 0 && width > dimenMaxW) ||
                                (dimenMaxH > 0 && height > dimenMaxH)
                            ) {
                                resolve(false);
                            } else {
                                // file loaded sucessfully
                                resolve(true);
                            }
                            image.onerror = function (error) {
                                // file failed to load
                                resolve(false);
                            };
                        };
                    };
                    reader.onerror = function (error) {
                        // file failed to load
                        resolve(false);
                    };
                    reader.readAsDataURL(file);
                });
            }

            const uploadExtCheck = (name, mimeType, acceptType) => {
                const fileExt = "." + name.split(".").pop().toLowerCase();

                const allowed = acceptType.split(",");
                return allowed.includes(fileExt) && allowed.includes(mimeType);
            }

            this.validateFileInput = (file, acceptType) => {
                return new Promise(async (resolve) => {
                    if (!file) {
                        resolve('請上載檔案');
                    } else {
                        if (checkDoubleFileExtension(file.name)) {
                            resolve('請確保上傳的檔案名稱沒有「.」符號');
                        }

                        if (file.type.indexOf("image") > -1) {
                            const check = await imgSizeCheck(file);
                            if (!check) {
                                resolve('圖片過大 (最大5472x5472)');
                            }

                            if (file.size > 1024 * 1024 * 8) {
                                resolve('檔案過大 (最大8MB)');
                            }
                        } else if (file.type.indexOf("application") > -1) {
                            if (file.size > 1024 * 1024 * 10) {
                                resolve('檔案過大 (最大10MB)');
                            }
                        }

                        if (!uploadExtCheck(file.name.split(".").pop(), file.type, acceptType)) {
                            resolve('不支援檔案格式');
                        }

                        resolve(true);
                    }
                });
            }
        })()
    }
}