const displayError = (res) => {
    if (res.data !== undefined && res.data !== null) {
        if (typeof res.data === 'string') {
            if (res.data === "verification fail") {
                return "tokenExpired";
            } else if (res.data === "permission denied") {
                return "noPermission";
            }
            return res.data;
        } else {
            return JSON.stringify(res.data);
        }
    }

    return JSON.stringify(res);
}

export default {
    install(Vue, options) {
        Vue.prototype.$Fetcher = new (function () {
            const DataValid = Vue.prototype.$validate.DataValid;
            const devLog = Vue.prototype.$common.log;

            this.Login = async function (email, password) {
                try {
                    const payload = {
                        email,
                        password
                    };
                    const data = await Vue.prototype.$XHR.api('cms_admin_login', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: Login', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: Login');
                        return Promise.reject('發生錯誤，請與我們聯絡');
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Login', res);
                    return Promise.reject(displayError(res));
                }
            }

            this.Logout = async function (uid, token) {
                try {
                    const payload = {
                        admin_id: uid,
                        token
                    };
                    const data = await Vue.prototype.$XHR.api('cms_admin_logout', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: Logout');
                        return Promise.resolve(true);
                    } else {
                        devLog('----------> XHR [NULL]: Logout');
                        return Promise.reject('發生錯誤，請與我們聯絡');
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: Logout', res);
                    return Promise.reject(displayError(res));
                }
            }

            this.GetUsers = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('cms_get_users', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetUsers');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetUsers');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUsers', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetUserById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('cms_get_user_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetUserById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetUserById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUserById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateUser = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid,
                    ...upload,
                };

                try {
                    await Vue.prototype.$XHR.api('cms_update_user', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateUser');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateUser', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "phone duplicate") {
                        return Promise.reject("電話號碼已被登記");
                    } else if (res.data === "email duplicate") {
                        return Promise.reject("電郵地址已被登記");
                    } else if (res.data === "email format not match") {
                        return Promise.reject("電郵地址格式錯誤");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetAppointments = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_appointment_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetAppointments');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetAppointments');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAppointments', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetAppointmentById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_appointment_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetAppointmentById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetAppointmentById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAppointmentById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateAppointment = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    appointment_id: id,
                    user_token: token,
                    verify_id: uid,
                    ...upload,
                };

                try {
                    await Vue.prototype.$XHR.api('cms_edit_appointment', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateAppointment');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateAppointment', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "appointment not found") {
                        return Promise.reject("找不到預約");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetPackages = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('cms_get_coupon_packages', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetPackages');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetPackages');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPackages', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetPackageById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('cms_get_coupon_package_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetPackageById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetPackageById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPackageById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdatePackage = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid,
                    ...upload,
                };

                try {
                    await Vue.prototype.$XHR.api('cms_update_coupon_package', payload);
                    devLog('----------> XHR [SUCCESS]: UpdatePackage');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdatePackage', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "coupon group not found") {
                        return Promise.reject("找不到套票");
                    } else if (res.data === "name cannot be empty") {
                        return Promise.reject("請輸入套票標題");
                    } else if (res.data === "please upload a cover") {
                        return Promise.reject("請上載套票封面");
                    } else if (res.data === "please add at least one coupon") {
                        return Promise.reject("至少新增一張換領券");
                    } else if (res.data === "coupon not found" || res.data === "some coupon not found") {
                        return Promise.reject("找不到換領券");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.NewPackage = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    user_token: token,
                    verify_id: uid,
                };

                try {
                    await Vue.prototype.$XHR.api('cms_add_coupon_package', payload);
                    devLog('----------> XHR [SUCCESS]: NewPackage');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewPackage', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "coupon group not found") {
                        return Promise.reject("找不到套票");
                    } else if (res.data === "name cannot be empty") {
                        return Promise.reject("請輸入套票標題");
                    } else if (res.data === "please upload a cover") {
                        return Promise.reject("請上載套票封面");
                    } else if (res.data === "please add at least one coupon") {
                        return Promise.reject("至少新增一張換領券");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.DeletePackage = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid,
                };

                try {
                    await Vue.prototype.$XHR.api('cms_delete_coupon_package', payload);
                    devLog('----------> XHR [SUCCESS]: DeletePackage');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: DeletePackage', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetUserCoupons = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('cms_get_user_coupon', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetUserCoupons');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetUserCoupons');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUserCoupons', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetUserCouponById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('cms_get_user_coupon_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetUserCouponById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetUserCouponById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetUserCouponById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateUserCouponStatus = async function (coupon_id, user_id, coupon_status, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    coupon_id,
                    user_id,
                    coupon_status,
                    user_token: token,
                    verify_id: uid,
                };

                try {
                    await Vue.prototype.$XHR.api('update_user_coupon_status', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateUserCouponStatus');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateUserCouponStatus', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "user coupon not found") {
                        return Promise.reject("找不到換領券");
                    } else if (res.data === "update user coupon status is not allowed") {
                        return Promise.reject("未能更新換領狀態");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetOrders = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_coupon_order_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetOrders');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetOrders');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrders', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetOrderById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    keep_promo_code: true,
                    get_user_data: true,
                    get_user_coupon_data: true,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_coupon_order_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetOrderById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetOrderById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetOrderById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateOrderStatus = async function (id, status, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    status,
                    user_token: token,
                    verify_id: uid,
                };

                try {
                    await Vue.prototype.$XHR.api('update_coupon_order', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateOrderStatus');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateOrderStatus', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "coupon order not found") {
                        return Promise.reject("找不到訂單");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetPromos = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_promo_code_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetPromos');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetPromos');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPromos', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetPromoById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_promo_code_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetPromoById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetPromoById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetPromoById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.NewPromo = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    user_token: token,
                    verify_id: uid,
                };

                try {
                    await Vue.prototype.$XHR.api('add_promo_code', payload);
                    devLog('----------> XHR [SUCCESS]: NewPromo');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewPromo', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "discount code exists") {
                        return Promise.reject("優惠碼已被使用");
                    } else if (res.data === "discount code generation fail") {
                        return Promise.reject("未能生成優惠碼");
                    } else if (res.data === "please select at least one user") {
                        return Promise.reject("請選擇至少一個指定帳戶");
                    } else if (res.data === "user not found") {
                        return Promise.reject("找不到指定帳戶");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdatePromo = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid,
                    ...upload,
                };

                try {
                    await Vue.prototype.$XHR.api('update_promo_code', payload);
                    devLog('----------> XHR [SUCCESS]: UpdatePromo');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdatePromo', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "promo code not found") {
                        return Promise.reject("找不到優惠");
                    }else if (res.data === "discount code exists") {
                        return Promise.reject("優惠碼已被使用");
                    } else if (res.data === "discount code generation fail") {
                        return Promise.reject("未能生成優惠碼");
                    } else if (res.data === "please select at least one user") {
                        return Promise.reject("請選擇至少一個指定帳戶");
                    } else if (res.data === "user not found") {
                        return Promise.reject("找不到指定帳戶");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetEnquiries = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_contact_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetEnquiries');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetEnquiries');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetEnquiries', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetStaffs = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('cms_get_staffs', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetStaffs');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetStaffs');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStaffs', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetStaffById = async function (id, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('cms_get_staff_by_id', payload);
                    if (DataValid(data)) {
                        devLog('----------> XHR [SUCCESS]: GetStaffById', data);
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetStaffById');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetStaffById', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.UpdateStaff = async function (id, upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    id,
                    user_token: token,
                    verify_id: uid,
                    ...upload,
                };

                try {
                    await Vue.prototype.$XHR.api('cms_update_staff', payload);
                    devLog('----------> XHR [SUCCESS]: UpdateStaff');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: UpdateStaff', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "phone duplicate") {
                        return Promise.reject("電話號碼已被登記");
                    } else if (res.data === "email duplicate") {
                        return Promise.reject("電郵地址已被登記");
                    } else if (res.data === "email format not match") {
                        return Promise.reject("電郵地址格式錯誤");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.NewStaff = async function (upload, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                if (upload["data_status"]) {
                    delete upload["data_status"];
                }

                const payload = {
                    ...upload,
                    user_token: token,
                    verify_id: uid,
                };

                try {
                    await Vue.prototype.$XHR.api('cms_add_staff', payload);
                    devLog('----------> XHR [SUCCESS]: NewStaff');
                    return Promise.resolve(true);
                } catch (res) {
                    devLog('----------> XHR [FAIL]: NewStaff', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else if (res.data === "phone duplicate") {
                        return Promise.reject("電話號碼已被登記");
                    } else if (res.data === "email duplicate") {
                        return Promise.reject("電郵地址已被登記");
                    } else if (res.data === "email format not match") {
                        return Promise.reject("電郵地址格式錯誤");
                    } else if (res.data === "password cannot be empty") {
                        return Promise.reject("請設定密碼");
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetSalesReport = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_sales_report', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetSalesReport');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetSalesReport');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetSalesReport', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetAppointmentReport = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_appointment_report', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetAppointmentReport');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetAppointmentReport');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetAppointmentReport', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }

            this.GetTransferRecordOfUserCoupon = async function (filters = {}, uid, token) {
                if (uid === undefined || uid === null || !token) {
                    return Promise.reject("tokenExpired");
                }

                const payload = {
                    ...filters,
                    user_token: token,
                    verify_id: uid
                };

                try {
                    const data = await Vue.prototype.$XHR.api('get_transferred_user_coupon_list', payload);
                    if (data['total'] && data['total'] > 0 && DataValid(data['data'])) {
                        devLog('----------> XHR [SUCCESS]: GetTransferRecordOfUserCoupon');
                        devLog(data)
                        return Promise.resolve(data);
                    } else {
                        devLog('----------> XHR [NULL]: GetTransferRecordOfUserCoupon');
                        return Promise.reject(null);
                    }
                } catch (res) {
                    devLog('----------> XHR [FAIL]: GetTransferRecordOfUserCoupon', res);
                    const err = displayError(res);
                    if (err === "noPermission" || err === "tokenExpired") {
                        return Promise.reject(err);
                    } else {
                        return Promise.reject(null);
                    }
                }
            }
        });
    },
}