/**
 * user_type：
 */

export const cannotAccessList = {
  
}

export const defaultPage = {
  staff: '/member',
}
