<script>
import Cookies from 'js-cookie'
import { mapActions } from 'vuex';
import { cannotAccessList } from '@/assets/cannotAccessList';

export default {
  name: 'TokenMixin',
  methods: {
    ...mapActions(['setAlert']),
    getCurrentUserData() {
      const data = Cookies.get('MARS_ANT_CMS_LID')

      if (data !== undefined && data !== null && data !== '') {
        return JSON.parse(Cookies.get('MARS_ANT_CMS_LID'))
      }

      return {}
    },
    /**
     * @param {Object} data - { id: -1, user_type: '', username: '', token }
     */
    setUserData(data) {
      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('MARS_ANT_CMS_LID')
      }

      Cookies.set('MARS_ANT_CMS_LID', JSON.stringify(data), {
        path: '/',
        expires: new Date(new Date().getTime() + 2 * 60 * 60 * 1000), // expires after 2 hour
        sameSite: 'strict',
        secure: true,
      })
    },
    getUserType() {
      const userData = this.getCurrentUserData()
      return userData.user_type
    },
    removeUserCookies() {
      const userData = this.getCurrentUserData();
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('MARS_ANT_CMS_LID')
      }
    },
    checkUserData() {
      let resultType = false;
      const userData = this.getCurrentUserData();

      if (this.$validate.DataValid(userData)) {
        if (
          this.$validate.DataValid(userData.id) &&
          this.$validate.DataValid(userData.token)
        ) {
          resultType = true;
        }
      }

      if (!resultType) {
        this.forceLogout()
      } else {
        const linkName = this.$route.name;
        if (linkName && cannotAccessList[linkName] && cannotAccessList[linkName].length > 0 && cannotAccessList[linkName].includes(userData.user_type)) {
          resultType = false;
          this.setAlert({
            message: "noPermission",
          })
          this.$store.dispatch('setInitLoading', false)
        }
      }

      return resultType;
    },

    async forceLogout(alert = true) {
      this.$store.dispatch('setInitLoading', true)

      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData.id) && userData.token) {
        try {
          await this.$Fetcher.Logout(userData.id, userData.token)
          this._finishLogout(alert)
        } catch {
          this._finishLogout(alert)
        }
      } else {
        this._finishLogout(alert)
      }
    },
    _finishLogout(alert = true) {
      this.removeUserCookies()
      if (alert === true) {
        this.$store.dispatch('toggleSnack', {
          message: 'tokenExpired',
          redirect: 'login',
        })
      } else {
        this.$router.replace({ name: 'login' })
        this.$store.dispatch('setInitLoading', false)
      }
    },
  },
}
</script>
