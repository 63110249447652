<template>
    <v-dialog v-model="open" scrollable :max-width="maxWidth" persistent :eager="eager">
      <v-card>
        <v-card-title
          v-if="title"
          class="text-h6 lighten-1 d-flex align-start"
          :class="{
            'accent--text': type === 'info',
            'success--text': type === 'success',
            'error--text': type === 'error',
          }"
          style="white-space: pre-line; line-height: 1.5;"
        >
          <v-icon v-if="type === 'info'" color="accent" class="mr-3" size="30">{{ icon.mdiInformationOutline }}</v-icon>
          <v-icon v-else-if="type === 'success'" color="success" class="mr-3" size="30">{{ icon.mdiCheckCircleOutline }}</v-icon>
          <v-icon v-else-if="type === 'error'" color="error" class="mr-3" size="30">{{ icon.mdiAlertOutline }}</v-icon>
          {{ title }}
        </v-card-title>
  
        <v-card-text style="white-space: pre-line; line-height: 1.8;">
          <slot name="content"></slot>
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-actions>
          <slot name="bottomLeft"></slot>
          <v-spacer></v-spacer>
          <v-btn
            v-if="enableCancel"
            color="colorGrey"
            :disabled="disabled"
            small
            :loading="loading"
            @click.prevent="_dialogAction(false)"
            class="colorWhite--text"
          >取消</v-btn>
          <v-btn
            color="primary"
            :disabled="disabled"
            :loading="loading"
            small
            @click.prevent="_dialogAction(true)"
          >{{ confirmBtnTitle }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import {
    mdiAlertOutline,
    mdiInformationOutline,
    mdiCheckCircleOutline,
  } from "@mdi/js";
  export default {
    name: "Dialog",
    props: {
      open: {
        type: Boolean,
        required: true,
        default: false,
      },
      maxWidth: {
        default: 540,
        required: false,
      },
      type: {
        type: String | null,
        required: false,
        default: "info",
      },
      title: {
        type: String,
        required: false,
        default: "",
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      loading: {
        type: Boolean,
        required: false,
        default: false,
      },
      enableCancel: {
        type: Boolean,
        required: false,
        default: false,
      },
      confirmBtnTitle: {
        type: String,
        required: false,
        default: "確認",
      },
      eager: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data: () => ({
      icon: {
        mdiAlertOutline,
        mdiInformationOutline,
        mdiCheckCircleOutline,
      },
    }),
    methods: {
      _dialogAction(action) {
        if (this.loading) {
          return;
        }
        this.$emit("close", action);
      },
    },
  };
  </script>
  