/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  cannotAccessList,
  defaultPage
} from '@/assets/cannotAccessList';
import TokenMixin from '@/mixins/TokenMixin'
import Validator from '@/prototype/Validator'
import Error from '@/views/Error.vue';

Vue.use(VueRouter);
Vue.mixin(TokenMixin)
Vue.use(Validator)
const vm = new Vue();

const routes = [{
  path: '/',
  redirect: 'login',
},
{
  path: '/login',
  name: 'login',
  component: () => import('@/views/pages/Login.vue'),
  meta: {
    layout: 'blank',
    title: '登入',
    skipAuth: true,
  },
},
{
  path: '/member',
  name: 'Member',
  component: () => import('@/views/pages/user/Member.vue'),
  meta: {
    layout: 'content',
    title: '會員',
    requireAuth: true,
  },
},
{
  path: '/member/detail/:id',
  name: 'MemberDetail',
  component: () => import('@/views/pages/user/MemberDetail.vue'),
  meta: {
    layout: 'content',
    title: '會員詳細',
    requireAuth: true,
  },
},
{
  path: '/appointment',
  name: 'Appointment',
  component: () => import('@/views/pages/appointment/Appointment.vue'),
  meta: {
    layout: 'content',
    title: '預約',
    requireAuth: true,
  },
},
{
  path: '/appointment/detail/:id',
  name: 'AppointmentDetail',
  component: () => import('@/views/pages/appointment/AppointmentDetail.vue'),
  meta: {
    layout: 'content',
    title: '預約詳細',
    requireAuth: true,
  },
},
{
  path: '/package',
  name: 'Package',
  component: () => import('@/views/pages/package/Package.vue'),
  meta: {
    layout: 'content',
    title: '套票設定',
    requireAuth: true,
  },
},
{
  path: '/package/create',
  name: 'CreatePackage',
  component: () => import('@/views/pages/package/PackageDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增套票',
    requireAuth: true,
  },
},
{
  path: '/package/detail/:id',
  name: 'PackageDetail',
  component: () => import('@/views/pages/package/PackageDetail.vue'),
  meta: {
    layout: 'content',
    title: '套票詳細',
    requireAuth: true,
  },
},
{
  path: '/user-coupon',
  name: 'UserCoupon',
  component: () => import('@/views/pages/package/UserCoupon.vue'),
  meta: {
    layout: 'content',
    title: '換領券',
    requireAuth: true,
  },
},
{
  path: '/user-coupon/detail/:id',
  name: 'UserCouponDetail',
  component: () => import('@/views/pages/package/UserCouponDetail.vue'),
  meta: {
    layout: 'content',
    title: '換領券詳細',
    requireAuth: true,
  },
},
{
  path: '/order',
  name: 'Order',
  component: () => import('@/views/pages/package/Order.vue'),
  meta: {
    layout: 'content',
    title: '訂單',
    requireAuth: true,
  },
},
{
  path: '/order/detail/:id',
  name: 'OrderDetail',
  component: () => import('@/views/pages/package/OrderDetail.vue'),
  meta: {
    layout: 'content',
    title: '訂單詳細',
    requireAuth: true,
  },
},
{
  path: '/promo',
  name: 'Promo',
  component: () => import('@/views/pages/promo/Promo.vue'),
  meta: {
    layout: 'content',
    title: '優惠券',
    requireAuth: true,
  },
},
{
  path: '/promo/create',
  name: 'CreatePromo',
  component: () => import('@/views/pages/promo/PromoDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增優惠券',
    requireAuth: true,
  },
},
{
  path: '/promo/detail/:id',
  name: 'PromoDetail',
  component: () => import('@/views/pages/promo/PromoDetail.vue'),
  meta: {
    layout: 'content',
    title: '優惠券詳細',
    requireAuth: true,
  },
},
{
  path: '/enquiry',
  name: 'Enquiry',
  component: () => import('@/views/pages/Enquiry.vue'),
  meta: {
    layout: 'content',
    title: '聯絡我們',
    requireAuth: true,
  },
},
{
  path: '/staff',
  name: 'Staff',
  component: () => import('@/views/pages/user/Staff.vue'),
  meta: {
    layout: 'content',
    title: '員工',
    requireAuth: true,
  },
},
{
  path: '/staff/create',
  name: 'CreateStaff',
  component: () => import('@/views/pages/user/StaffDetail.vue'),
  meta: {
    layout: 'content',
    title: '新增員工',
    requireAuth: true,
  },
},
{
  path: '/staff/detail/:id',
  name: 'StaffDetail',
  component: () => import('@/views/pages/user/StaffDetail.vue'),
  meta: {
    layout: 'content',
    title: '員工詳細',
    requireAuth: true,
  },
},
{
  path: '/report',
  name: 'Report',
  component: () => import('@/views/pages/Report.vue'),
  meta: {
    layout: 'content',
    title: '報告',
    requireAuth: true,
  },
},
{
  path: '*',
  name: 'Error',
  component: Error,
  meta: { title: 'Error' },
},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('setInitLoading', false);
  store.dispatch('setLoading', false);
  window.scrollTo(0, 0);

  if (to.meta.requireAuth) {
    const userData = vm.getCurrentUserData();
    const isUserLogin = vm.$validate.DataValid(userData);

    if (isUserLogin) {
      if (to.meta.cannotAccess && to.meta.cannotAccess.includes(userData.user_type)) {
        next(defaultPage[userData.user_type]);
      } else {
        next();
      }
    } else {
      next('/login');
    }
  } else if (to.meta.skipAuth) {
    const userData = vm.getCurrentUserData();
    if (vm.$validate.DataValid(userData) && userData.user_type) {
      if (to.name === 'login' || to.meta.cannotAccess && to.meta.cannotAccess.includes(userData.user_type)) {
        next(defaultPage[userData.user_type]);
      } else {
        next();
      }
    } else {
      next();
    }
  } else {
    next();
  }
})

const DEFAULT_TITLE = 'Mars Ant Motors CMS'

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = (`${to.meta.title} - ` || '') + DEFAULT_TITLE
  })
})

export default router
